import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InvoiceType } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('invoiceTypes');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectItemsDictionary = createSelector(
  selectAllItems,
  (invoiceTypes: InvoiceType[]) =>
    invoiceTypes?.reduce((invoiceTypesObj, invoiceType) => {
      let document_type_codes_dictionary = {};
      if (invoiceType?.document_type_codes?.length) {
        document_type_codes_dictionary =
          invoiceType?.document_type_codes.reduce((codesObj, code) => {
            return { ...codesObj, [code.id]: code };
          });
      }
      return {
        ...invoiceTypesObj,
        [invoiceType.id]: { ...invoiceType, document_type_codes_dictionary },
      };
    }, {}),
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
