import { createAction, props } from '@ngrx/store';

import { InvoiceType } from '../../models';

export const loadRequest = createAction('[Invoice Type] Load Request');
export const loadSuccess = createAction(
  '[Invoice Type] Load Success',
  props<{ items: InvoiceType[] }>(),
);
export const loadFailure = createAction(
  '[Invoice Type] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Invoice Type] Reset State');
