import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { InvoiceTypeService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class InvoiceTypeStoreEffects {
  constructor(
    private dataService: InvoiceTypeService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() =>
        this.dataService.load().pipe(
          map(({ data: items }: IResponseSuccess) => {
            return fromActions.loadSuccess({
              items,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
